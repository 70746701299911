@media screen and (min-width: 756px) {
    .annotationLayer .textWidgetAnnotation input,
    .annotationLayer .textWidgetAnnotation textarea,
    .annotationLayer .choiceWidgetAnnotation select,
    .annotationLayer .buttonWidgetAnnotation.checkBox input,
    .annotationLayer .buttonWidgetAnnotation.radioButton input {
        font-size: calc(9px * var(--scale-factor)) !important;
        // font-weight: 600 !important;
        padding: 0 !important;
        border: unset !important;
        margin: unset !important;
        opacity: 1 !important;
        font-family: 'Rubik' !important;
        direction: rtl !important;

        &.b360TxtInputError {
            border: 2px solid red !important;
            border-radius: 5px !important;
            opacity: 1 !important;
        }
    }

    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before,
    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
        background-color: blue !important;
        width: 2px !important;
        left: 31% !important;
        top: 22% !important;
        opacity: 1 !important;
    }
}

@media screen and (max-width: 756px) {
    .annotationLayer .textWidgetAnnotation input,
    .annotationLayer .textWidgetAnnotation textarea,
    .annotationLayer .choiceWidgetAnnotation select,
    .annotationLayer .buttonWidgetAnnotation.checkBox input,
    .annotationLayer .buttonWidgetAnnotation.radioButton input {
        font-size: 8px !important;
        padding: 0 !important;
        border: unset !important;
        margin: unset !important;
        opacity: 1 !important;
        direction: rtl !important;
        
        

        &.b360TxtInputError {
            border: 1px solid red !important;
            border-radius: 5px !important;
            opacity: 1 !important;
        }
    }

    

    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before,
    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
        background-color: blue !important;
        width: 1px !important;
        left: 39% !important;
        top: 25% !important;
    }
}

.annotationLayer section {
    canvas.annotationContent {
        visibility: hidden;
        position: absolute;
    }
    input,
    textarea {
        display: block !important;
    }
}

span.markedContent {
    span {
        color: transparent !important;
    }
}

span[role='presentation'] {
    display: none !important;
}

// ngx-extended-pdf-viewer
ngx-extended-pdf-viewer
    .annotationLayer
    .textWidgetAnnotation
    :is(input, textarea),
ngx-extended-pdf-viewer .annotationLayer .choiceWidgetAnnotation select,
ngx-extended-pdf-viewer
    .annotationLayer
    .buttonWidgetAnnotation:is(.checkBox, .radioButton)
    input {
    background-color: #dee5ff !important;
    box-sizing: border-box !important;
    border: 1px solid #c1c1c1 !important;
    border-radius: 5px;
    direction: rtl ;
    background-image: none !important;
    &:hover {
        border: 1px solid #d57000 !important;
        border-radius: 5px !important;
    }

    &:disabled {
        background-color: transparent !important;
        border: unset !important;
        border-radius: unset;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}
ngx-extended-pdf-viewer
    .annotationLayer
    .buttonWidgetAnnotation.checkBox
    input:checked:before,
ngx-extended-pdf-viewer
    .annotationLayer
    .buttonWidgetAnnotation.checkBox
    input:checked:after {
    height: 80% !important;
    left: 48% !important;
    width: 1px !important;
    top: 3px !important;
}
ngx-extended-pdf-viewer
    .annotationLayer
    .buttonWidgetAnnotation.checkBox
    input:checked:before,
ngx-extended-pdf-viewer
    .annotationLayer
    .buttonWidgetAnnotation.checkBox
    input:checked:after,
ngx-extended-pdf-viewer
    .annotationLayer
    .buttonWidgetAnnotation.radioButton
    input:checked:before {
    background-color: blue !important;
}
