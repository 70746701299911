%BASE_B360_BTN_STYLE{
    border-radius: 10px;
    & > .mat-icon {
        margin: 0;
        width: 24px;
        font-size: 24px;
        height: 24px;
    }
}

%FILE_SELECTED {
    &.fileSelected {
        background-color: #79c469;
        color: #0a4200;
    }
}



button.b360BtnStyling{
    border-radius: var(--bis-btn-border-radius);
    &.b360PrimaryBtnColor{
        background-color: rgba(253, 241, 234, 1);
        color: #ff8400;
    }

    &.b360WarnBtnColor {
        background-color: #FCE9E9;
        color: #DB1B1B;
    }

    &.b360InfoBtnColor {
        background-color: #e6eff3;
        color: #1b489f;
    }
}


button.b360M3Btn.mat-mdc-raised-button:not(:disabled),
a.b360M3Btn.mat-mdc-raised-button:not(:disabled) {
    background-color: #FFF1E8;
    border-radius: 10px;
    color:#86521A ;
    gap:8px;
    height: 40px;
}




// DEPRECATED STYLES

button.b360PrimaryBtn.mat-mdc-raised-button {
    background-color: rgba(253, 241, 234, 1);
    color: #ff8400;

    @extend %BASE_B360_BTN_STYLE;
    @extend %FILE_SELECTED;
}



button.b360PrimaryBtn.mat-mdc-unelevated-button {
    background-color: rgba(253, 241, 234, 1);
    color: #ff8400;
    border: 1px solid #fdead4;

    @extend %BASE_B360_BTN_STYLE;
    @extend %FILE_SELECTED;
}

button.b360PrimaryBtnColor {
    background-color: var(--bis-primary-btn-color);
    color: var(--bis-primary-btn-text-color);
}

button.b360PrimaryBtnIcon.mat-mdc-raised-button {
    background-color: rgba(253, 241, 234, 1);
    color: #ff8400;
    font-weight: 400;
    border-radius: 10px;
    margin: 0;

    & > .mat-icon {
        margin: 0;
        width: 18px;
        height: 18px;
        font-size: 18px;
    }
}

.b360BigIconBtn {
    font-family: var(--mdc-filled-button-label-text-font);
    font-size: var(--mdc-filled-button-label-text-size);
    letter-spacing: var(--mdc-filled-button-label-text-tracking);
    font-weight: var(--mdc-filled-button-label-text-weight);
    text-transform: var(--mdc-filled-button-label-text-transform);

    border-radius: var(--mdc-filled-button-container-shape);
    padding: 0 var(--mat-filled-button-horizontal-padding, 16px);
    border: unset;

    background-color: #0a4200;
}

