.b360TableTopbar {
    // align-items: center;

    .b360TableToggle {
        grid-area: 1 / -1 / -1 / -5;
        justify-self: end;
        align-self: end;
        padding: 2rem 0;
    }

    .b360TableActions {
        grid-area: 1/-2/-1/-3;
        align-self: end;
        padding: 0.5rem 0;
    }

    .downloadExcel {
        mat-icon.xlsIcon {
            font-size: 20px;
            height: 20px;
            width: 20px;
        }
    }
}

.tableWrapper {
    position: relative;

    .b360GlobalTableStyling {
        min-height: 500px;
        max-height: calc(100vh - 30rem);
        overflow-y: auto;
        min-width: unset;
        border: 1px solid var(--mat-table-row-item-outline-color);
    }

    button.b360GlobalTableFilterBtn {
        position: absolute;
        padding: 0;
        right: -40px;
        top: 0;
        border-radius: none;
        border-radius: unset;
        height: 56px;
        width: 40px;
        background-color: var(--mat-table-background-color);
        border: 1px solid var(--mat-table-row-item-outline-color);
    }

    span.clickable {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
            color: var(--bis-primary-color);
        }
    }
}

.b360TableNoData {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .noDataImgWrapper {
        width: 400px;
        height: 267px;
        position: relative;
        margin: 3rem 0;

        img {
            border-radius: 50%;
        }
    }
}

.b360_pagination {
    background-color: var(--mat-paginator-container-background-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid var(--mat-table-row-item-outline-color);
    border-left: 1px solid var(--mat-table-row-item-outline-color);
    border-bottom: 1px solid var(--mat-table-row-item-outline-color);

    .selected {
        margin-bottom: 0;
        padding-right: 20px;
    }
}

.mat-column-employeeImg {
    align-items: center;
    justify-content: center;
}

// allow sort to take as much space as it needs
.mat-mdc-menu-panel.b360GlobalFilterPanel {
    max-width: unset;
}

.b360-cell {
    width: 100%;

    span {
        text-overflow: ellipsis;
        width: 93%;
        overflow: hidden;
        white-space: nowrap;
    }
}

mat-cell.mat-mdc-cell,
mat-footer-cell.mat-mdc-footer-cell,
mat-header-cell.mat-mdc-header-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    text-wrap: balance;
    padding: 0;
    padding-inline-end: 1rem;
}

div.mat-sort-header-container > div.mat-sort-header-content {
    text-align: unset;
}

[dir='rtl']mat-cell.mat-mdc-cell,
[dir='rtl']mat-header-cell.mat-mdc-header-cell,
[dir='rtl'] mat-footer-cell.mat-mdc-footer-cell {
    &:first-of-type:not(:only-of-type) {
        padding-right: 10px;
    }

    &:last-of-type:not(:only-of-type) {
        padding-left: 10px;
    }
}

[dir='ltr']mat-cell.mat-mdc-cell,
[dir='ltr']mat-header-cell.mat-mdc-header-cell,
[dir='ltr'] mat-footer-cell.mat-mdc-footer-cell {
    &:first-of-type:not(:only-of-type) {
        padding-left: 10px;
    }

    &:last-of-type:not(:only-of-type) {
        padding-right: 10px;
    }
}
