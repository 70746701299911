.lh-1 {
    line-height: 0.6 !important;
}

.lh-2 {
    line-height: 0.8 !important;
}
.lh-3 {
    line-height: 1 !important;
}

.lh-4 {
    line-height: 1.2 !important;
}
.lh-5 {
    line-height: 1.5 !important;
}
