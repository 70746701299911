@use 'sass:map';
@use 'sass:string';
@use 'sass:math';

@use '@angular/material' as mat;
@use 'grid';
@use 'fonts';
@use 'spacing';
@use './core/table.scss';
@use 'scroller';
@use 'status';
@use 'buttons';
@use 'pdf-viewer-global';
@use 'google-maps';
@use 'line-height';
@use 'variables' as var;
@use 'swiper';

@use 'backdrop';
@use 'cropper';
@use 'b360-icons';
@use 'flex-helpers';
@use 'density-helpers';
@use 'form-field';
@use 'radio-button';
@use 'checkbox';
@use 'contact-details-menu';
@use 'gleap';
@use 'virtual-scroll';

@include mat.elevation-classes();
@include mat.app-background();

@include mat.typography-hierarchy(var.$b360-typography);

@include mat.all-component-themes(var.$b360-light-theme);

$primary-color: map.get(var.$b360-primary, 'primary');

@mixin tagsColor($theme) {
    $color: map.get($theme, 'color');

    // Get the accent color palette from the color-config.
    $accent-palette: map.get($color, 'accent');
    $primary-palette: map.get($color, 'primary');
    $warn-palette: map.get($color, 'warn');

    $primary-color-700: mat.get-theme-color($theme, primary, 700);
    $warn-color-700: mat.get-theme-color($theme, warn, 700);
    $accent-color-900: mat.get-theme-color($theme, accent, 900);

    .cdk-overlay-container {
        z-index: 9999;
    }

    h2,
    span,    
    p,
    h1,
    h3,
    h4,
    h5,
    h6 {
        &.primary-text {
            color: $primary-color-700;
        }
        &.warn-text {
            color: $warn-color-700;
        }
     

        &:not(.mat-accent):not(.mdc-button__label):not(.primary-text):not(
                .warn-text
            ):not(.constWhiteColor):not(.constBlackColor) {
            // This will exclude the element itself if it has the .mat-accent class
            color: $accent-color-900;
        }

        .mat-accent &,
        .mdc-button__label & {
            // This will exclude the element if any of its parents have the .mat-accent class
            color: inherit;
        }

        line-height: 1.2;
    }

    .constWhiteColor {
        color: white !important;
    }
    .constBlackColor {
        color: black !important;
    }

    mat-divider.mat-divider.primary-color {
        border-top-color: $primary-color-700;
        border-bottom-color: $primary-color-700;
    }
    mat-divider.mat-divider.mat-divider-vertical.primary-color {
        border-right-color: $primary-color-700;
        border-left-color: $primary-color-700;
    }
}

b360-employee-chip {
    padding: 0.1rem;
}

.b360NgSrcWrapper {
    width: inherit;
    height: inherit;
    position: relative;
}

.h-100vh {
    height: 100vh;
}

.dark_mode {
    @include tagsColor(var.$b360-dark-theme);
    @include mat.all-component-colors(var.$b360-dark-theme);
}

.light_mode {
    @include tagsColor(var.$b360-light-theme);
}

html {
    font-size: 10px;

    body {
        margin: 0;
        background-color: var(--bis-primary-background); // Set dark background
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .mat-mdc-form-field-hint {
        color: var(--mdc-outlined-text-field-label-text-color);
    }

    .b360roundImg {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: cover;
    }

    .userInitialsFallbackCircle {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        background-color: var(--bis-default-avatar-bg);
    }
}

.docTypeStyling {
    padding: 0.5rem;
    font-size: 14px;
    border-radius: 5px;
    background-color: #ff6700;
    &.kit {
        background-color: #0075ff;
    }
}

@media screen and (max-width: 756px) {
    html,
    body {
        height: 100%;
        margin: 0;
        padding: 0;

        overflow-x: hidden;
    }

    app-root {
        overflow-y: auto;
    }
}

mat-error.mat-mdc-form-field-error {
    display: flex;
    align-items: center;
}

// dont limit the width of the menu panel
.mat-mdc-menu-panel {
    max-width: unset !important;
}

.round {
    border-radius: 50%;
}

.w-100 {
    width: 100%;
}

body {
    .b360CardSideIcon {
        z-index: 1;
        position: absolute;
        justify-content: flex-end;
        height: 7rem;
        width: 7rem;
        box-shadow: 0px 2px 5px #00000029;
        mat-icon {
            position: relative;
            top: 13px;
            font-size: 35px;
            height: 35px;
            width: 35px;
        }
    }

    .b360DialogWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem;
        .b360DialogOverlappingIcon {
            background-color: var(--bis-dialog-icon-bg);
            position: absolute;
            top: -34px;
            border-radius: 50%;
            padding: 2rem;
            color: var(--bis-dialog-icon-color) !important;
            // color: white;
        }

        .noData {
            width: 200px;
            height: auto;
            h3 {
                text-align: center;
            }
        }
    }
}

.cdk-overlay-container {
    .cdk-global-overlay-wrapper {
        .mat-mdc-dialog-surface.mdc-dialog__surface {
            overflow: visible;
        }
    }
}

// standard border
.b360Border {
    border: 1px solid var(--mat-divider-color);
    border-radius: var(--bis-border-radius);
}

// global styles by dir
body[dir='ltr'] {
    .b360CardSideIcon {
        left: unset;
        right: 0;
        border-radius: 0 0 0 100%;
        mat-icon {
            right: 11px;
        }
    }

    .b360DialogOverlappingIcon {
        left: 50%;
        transform: translate(-50%, 0);
    }
    .mat-mdc-menu-panel {
        direction: ltr;
    }
    mat-accordion.mat-accordion {
        direction: ltr;
    }
}

body[dir='rtl'] {
    .b360CardSideIcon {
        right: unset;
        left: 0;
        border-radius: 0 0 100% 0;
        mat-icon {
            left: 11px;
        }
    }

    .b360DialogOverlappingIcon {
        right: 50%;
        transform: translate(50%, 0);
    }

    .mat-mdc-menu-panel {
        direction: rtl;
    }

    mat-accordion.mat-accordion {
        direction: rtl;
    }

    .cdk-global-overlay-wrapper {
        direction: rtl !important;
    }

    .cdk-overlay-pane,
    .cdk-overlay-panel {
        direction: rtl !important;
    }

    .cdk-virtual-scroll-viewport {
        direction: rtl !important;
    }
}

@function str-replace($string, $search, $replace: '') {
    $index: string.index($string, $search);
    @if $index {
        // Perform replacement when $search is found
        @return string.slice($string, 1, $index - 1) + $replace +
            string.slice($string, $index + string.length($search));
    }
    // Return original string if $search is not found
    @return $string;
}

@mixin svgIconSize($size) {
    // Convert the size to a string and perform the replacement
    $size-str: '#{ $size }';

    $class-size: str-replace($size-str, '.', '-');

    // Use the adjusted size for the class name
    mat-icon.mat-icon.scale#{$class-size} {
        transform: scale(#{$size});
    }
}

@for $i from 1 through 40 {
    $size: math.div($i, 10); // Updated division operation
    @include svgIconSize($size);
}
